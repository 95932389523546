import { css } from '@emotion/react';
import { spacing } from '@prototyp/gatsby-plugin-gumball/mixins/spacing';
import { font } from '@prototyp/gatsby-plugin-gumball/utils/font';
import { breakpoints } from 'style/variables';
import { primary } from 'modules/app/styles/CtaButton.styles';
import { root, medium, ghost } from 'modules/app/styles/Button.styles';

import { config } from 'style/grids.config';

export const contentWrapper = css`
  padding: ${spacing(40)} ${spacing(20)} ${spacing(80)};
  margin: 0 auto;
  position: relative;
  display: flex;
  flex-direction: column;

  @media ${breakpoints.medium} {
    ${config.base}
    padding: ${spacing(60)} ${spacing(64)} ${spacing(164)};
  }

  @media ${breakpoints.large} {
    padding: ${spacing(120)} ${spacing(64)} ${spacing(24)};
  }
`;

export const title = css`
  ${font.family.secondary};
  font-size: clamp(2.5rem, 2.1875rem + 1.5625vw, 3.75rem);
  line-height: clamp(2.75rem, 2.4375rem + 1.5625vw, 4rem);
  margin-bottom: ${spacing(8)};
  letter-spacing: -1px;
  font-weight: normal;

  @media ${breakpoints.large} {
    margin: 0 auto;
    margin-top: ${spacing(100)};
  }
`;

export const content = css`
  max-width: ${spacing(560)};
  margin: 0 auto;
`;

export const textWrapper = css`
  grid-column: 1 / span 7;
`;

export const description = css`
  color: hsl(var(--color-borderDark-5));
  ${font.size.large};
  margin-bottom: ${spacing(20)};

  @media ${breakpoints.medium} {
    ${font.size.xLarge};
    margin-bottom: ${spacing(40)};
  }
`;

export const wrapper = css`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;

  @media ${breakpoints.medium} {
    margin-top: 0;
  }
`;

export const details = css`
  font-family: Aeonik;
  font-size: 14px;
  line-height: calc(var(--unit) * 5);

  span {
    ${font.weight.bold}
  }
`;

export const logo = css`
  position: absolute;
  width: 100%;
  height: auto;
  top: 10%;

  @media ${breakpoints.medium} {
    top: 20%;
  }
`;

export const link = css`
  ${primary};

  background: hsl(var(--color-text-1));
  color: hsl(var(--color-white-11));

  &::before {
    background: hsl(var(--color-robin-2));
    border-radius: 2px;
  }

  &:hover,
  &:active {
    color: hsl(var(--color-text-1));
  }
`;

export const illustrationImage = css`
  width: 100%;
  min-width: ${spacing(800)};
`;

export const illustration = css`
  width: 100%;
  overflow: hidden;
  position: relative;
`;

export const imageAndTextWrapper = css`
  overflow: hidden;
  width: 100%;
  max-width: 400px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto 0;

  @media ${breakpoints.medium} {
    grid-column: 9 / span 4;
    margin-top: 0;
  }
`;

export const imageWrapper = css`
  position: relative;
`;

export const previewButton = css`
  ${root};
  ${ghost};
  ${medium};
  margin: 0 auto;
  width: max-content;
  border-radius: 100px;

  &:hover,
  &:active {
    background-color: hsl(var(--color-white-10));
  }

  @media ${breakpoints.medium} {
    grid-column: 9 / span 4;
    margin: 0 auto;
  }
`;

export const heaederImage = css`
  width: 100%;
  margin-bottom: 24px;
  z-index: 2;
`;

export const buttonSpecial = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 2;
`;
